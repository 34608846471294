<template>
  <!-- Page Content -->
  <div>
      <header>
        <h1 class="text-3xl font-semibold tracking-wide">Units - All</h1>
        <p class="mt-2 text-md" style="margin-left: 2px">
          {{ totalUnits }} Units
        </p>
      </header>

      <router-link
        class="w-40 float-right flex flex-row items-center justify-center p-2 text-white font-semibold bg-primary rounded cursor-pointer"
        :to="{ name: 'admin-import' }"
        v-if="hasAccess('units', 'import')"
      >
        Import
      </router-link>

      <!-- Unit All Content -->
      <!-- Unit All Table Content -->
      <table class="min-w-max w-full mt-5 table-auto">
        <!-- Unit All Table Content Header -->
        <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th
            class="py-3 px-6 text-center cursor-pointer"
            v-if="hasAccess('units', 'edit')"
          ></th>
          <th
            @click="sort('status')"
            class="heading-text"
            :class="{ sorting: currentSort === 'status' }"
          >
            Status
            <MenuDown
              v-if="isSorted('status', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('status', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('name')"
            class="heading-text"
            :class="{ sorting: currentSort === 'name' }"
          >
            Unit
            <MenuDown
              v-if="isSorted('name', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('name', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('price')"
            class="heading-text"
            :class="{ sorting: currentSort === 'price' }"
          >
            Price
            <MenuDown
              v-if="isSorted('price', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('price', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('floor')"
            class="heading-text"
            :class="{ sorting: currentSort === 'floor' }"
          >
            Floor
            <MenuDown
              v-if="isSorted('floor', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('floor', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('internalArea')"
            class="heading-text"
            :class="{ sorting: currentSort === 'internalArea' }"
          >
            Internal Area
            <MenuDown
              v-if="isSorted('internalArea', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('internalArea', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('externalArea')"
            class="heading-text"
            :class="{ sorting: currentSort === 'externalArea' }"
          >
            External Area
            <MenuDown
              v-if="isSorted('externalArea', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('externalArea', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('totalArea')"
            class="heading-text"
            :class="{ sorting: currentSort === 'totalArea' }"
          >
            Total Area
            <MenuDown
              v-if="isSorted('totalArea', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('totalArea', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('bedrooms')"
            class="heading-text"
            :class="{ sorting: currentSort === 'bedrooms' }"
          >
            Beds
            <MenuDown
              v-if="isSorted('bedrooms', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('bedrooms', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('bathrooms')"
            class="heading-text"
            :class="{ sorting: currentSort === 'bathrooms' }"
          >
            Baths
            <MenuDown
              v-if="isSorted('bathrooms', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('bathrooms', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('aspect')"
            class="heading-text"
            :class="{ sorting: currentSort === 'aspect' }"
          >
            Direction
            <MenuDown
              v-if="isSorted('aspect', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('aspect', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('view')"
            class="heading-text"
            :class="{ sorting: currentSort === 'view' }"
          >
            Outlook
            <MenuDown
              v-if="isSorted('view', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('view', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('rates')"
            class="heading-text"
            :class="{ sorting: currentSort === 'rates' }"
          >
            Rates
            <MenuDown
              v-if="isSorted('rates', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('rates', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('levies')"
            class="heading-text"
            :class="{ sorting: currentSort === 'levies' }"
          >
            Levies
            <MenuDown
              v-if="isSorted('levies', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('levies', 'asc')"
              class="inline-block absolute"
            />
          </th>
          <th
            @click="sort('shortfall')"
            class="heading-text"
            :class="{ sorting: currentSort === 'shortfall' }"
          >
            Shortfall
            <MenuDown
              v-if="isSorted('shortfall', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('shortfall', 'asc')"
              class="inline-block absolute"
            />
          </th>
                    <th
            @click="sort('shortfall')"
            class="heading-text"
            :class="{ sorting: currentSort === 'shortfall' }"
          >
            Pre-Reserved
            <MenuDown
              v-if="isSorted('pre-reserved', 'desc')"
              class="inline-block absolute"
            />
            <MenuUp
              v-if="isSorted('pre-reserved', 'asc')"
              class="inline-block absolute"
            />
          </th>
        </tr>
        </thead>

        <!-- Unit All Table Content Body -->
        <tbody class="text-gray-600 text-sm font-light">
        <tr
          v-for="unit in sortedUnits"
          :key="unit.id"
          class="border-b border-gray-200 hover:bg-gray-100"
        >
          <td
            class="py-3 px-6 text-left whitespace-nowrap"
            v-if="hasAccess('units', 'edit')"
          >
            <div class="flex item-center justify-center">
              <router-link
                :to="{ name: 'admin-update-unit', params: { id: unit.id } }"
                class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
              >
                <EditIcon />
              </router-link>
            </div>
          </td>

          <td class="py-3 px-6 text-center">
            <span
              v-if="unit.status === 'available'"
              class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs"
            >
              Available
            </span>

            <span
              v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'"
              class="bg-gray-200 text-gray-600 py-1 px-3 rounded-full text-xs"
            >Pending
            </span>

            <span
              v-if="unit.status === 'reserved'"
              class="bg-blue-200 text-blue-600 py-1 px-3 rounded-full text-xs"
            >
              Reserved
            </span>

            <span
              v-if="unit.status === 'sold'"
              class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs"
            >
              Sold
            </span>
          </td>

          <td class="py-3 px-6 text-center">
            {{ unit.name }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.price | prettyCurrency }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.floor }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.internalArea }}m&sup2;
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.externalArea }}m&sup2;
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.totalArea }}m&sup2;
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.bedrooms }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.bathrooms }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.aspect }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.view }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.rates | prettyCurrency }}
          </td>
          <td class="py-3 px-6 text-center">
            {{ unit.levies | prettyCurrency }}
          </td>
          <!-- shortfall body -->
          <td class="py-3 px-6 text-center">
            {{ unit.shortfall.toFixed(0) | prettyCurrency }}
            <sup>pm</sup>
          </td>
          <td class="py-3 px-6 text-center">
            <form>
               <input
                    v-model="unit.preReserved"
                    type="checkbox"
                    class="rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
                    @click="savePreReserved($event, unit.id, unit.name)"
                    >
            </form>
          </td>
        </tr>
        </tbody>
      </table>
      <!-- Unit All Table Content Pagination -->
      <p class="pagination-container">
        <button
          v-if="currentPage != 1"
          @click="prevPage"
          class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
        >
          &lt; Prev
        </button>

        <button
          v-if="(this.currentPage*this.pageSize) < this.units.length"
          @click="nextPage"
          class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
        >
          Next &gt;
        </button>
      </p>
  </div>
</template>

<script>
import {settingsCollection, unitsCollection
} from "../../firebase.js";
import isNil from "lodash/isNil";
import permissionsMixin from "@/mixins/permissions";
import EditIcon from "vue-material-design-icons/PencilOutline";
import MenuDown from "vue-material-design-icons/MenuDown.vue";
import MenuUp from "vue-material-design-icons/MenuUp.vue";
import {APP_ADMIN_PAGINATION} from "@/Constants";
import {reverse} from "lodash";

export default {
  name: "AdminUnitsList",
  mixins: [permissionsMixin],
  components: {
    EditIcon,
    MenuDown,
    MenuUp,
  },
  data: () => ({
    currentSort:'name',
    currentSortDir:'asc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    units: {
      isAircon: false,
      id: null,
      name: null,
      price: null,
      floor: null,
      internalArea: null,
      externalArea: null,
      totalArea: null,
      bedrooms: null,
      bathrooms: null,
      aspect: null,
      view: null,
      rates: null,
      levies: null,
      preReserved: false,
      guaranteedRental: null,

      guaranteedRentalAmount: null,
      status: null,
    },
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
    },
  }),
  created() {
    this.getSettings();
    this.getUnits("name", false);
  },
  methods: {
    savePreReserved(event, unitID) {
        //this.preReserved = !this.preReserved
        if (event.target.checked){
        unitsCollection
          .doc(unitID)
          .update({
            preReserved: true,
          })
        }
        else {
            unitsCollection
          .doc(unitID)
          .update({
            preReserved: false,
          })
        
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Pre Reserved Updated",
            });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Pre Reserved Update Error",
              text: error.message,
            });
          });
      }
      
    },
    getUnits(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        // .where("displayOnHomePage", "==", true)
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let units = [];

          snap.forEach((doc) => {
            units.push({
              id: doc.id,
              name: doc.data().name,
              price: doc.data().price,
              discountPrice: doc.data().discountPrice,
              floor: doc.data().floor,
              unitType: doc.data().type,
              aspect: doc.data().aspect,
              view: doc.data().view,
              bedrooms: doc.data().bedrooms,
              bathrooms: doc.data().bathrooms,
              parking: doc.data().parking,
              isAircon: doc.data().isAircon,
              storeroom: doc.data().storeroom,
              internalArea: doc.data().internalArea,
              externalArea: doc.data().externalArea,
              totalArea: doc.data().totalArea,
              levies: doc.data().levies,
              rates: doc.data().rates,
              guaranteedRental: doc.data().guaranteedRental,
              reserved: doc.data().reserved,
              twentyYearBondInstalment90: doc.data().twentyYearBondInstalment90,

              mainImageURL: doc.data().mainImageURL,
              slide2ImageURL: doc.data().slide2ImageURL,
              slide3ImageURL: doc.data().slide3ImageURL,

              displayOnHomePage: doc.data().displayOnHomePage,
              isGuaranteedRental: doc.data().isGuaranteedRental,
              unitRentalGuaranteed: doc.data().unitRentalGuaranteed,
              isOverrideAction: doc.data().isOverrideAction,
              unitOverrideAction: doc.data().unitOverrideAction,
              unitOverrideActionButton: doc.data().unitOverrideActionButton,
              status: doc.data().status,
              layout: doc.data().layout,

              isOneDayOnlyDiscount: doc.data().isOneDayOnlyDiscount,
              oneDayOnlyDiscount: doc.data().oneDayOnlyDiscount,
              oneDayOnlyDiscountDateStart: doc.data().oneDayOnlyDiscountDateStart,
              oneDayOnlyDiscountDateFinish: doc.data().oneDayOnlyDiscountDateFinish,
              shortfall: this.getShortFall(doc.data()),
              preReserved: doc.data().preReserved,
            });
          });

          if (orderByReverse) {
            this.units = reverse(units);
          } else {
            this.units = units;
          }

          //this.applyFilters();
          this.componentLoading = false;
        });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.interestRate = doc.data().interestRate;
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate
          ? doc.data().launchDate.toDate()
          : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount =
          doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount =
          doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType =
          doc.data().globalDiscountType || null;
      });
    },
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.units.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    getShortFall:function(unit) {
      if (this.settings.allowGlobalDiscount) {
        if (this.settings.globalDiscountType === 'amount') {
          const shortfall = (
              parseInt(
                  unit.levies +
                  unit.rates +
                  ((-(this.settings.interestRate / 100) / 12) *
                      (((unit.price - this.settings.globalDiscountAmount * 0.9) * -1) *
                          Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) + 0)) /
                  (Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) - 1)
              ) - unit.guaranteedRental
          )
          return shortfall
        }

        if (this.settings.globalDiscountType === 'percentage') {
          const shortfall = (
              (
                  unit.levies +
                  unit.rates +
                  ((-(this.settings.interestRate / 100) / 12) *
                      (((unit.price * ((100 - this.settings.globalDiscountAmount) / 100) * 0.9) * -1) *
                          Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) + 0)) /
                  (Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) - 1)
              ) - unit.guaranteedRental
          )
          return shortfall
        }
      }

      const shortfall = (
          (
              unit.levies +
              unit.rates +
              ((-(this.settings.interestRate / 100) / 12) *
                  (((unit.price * 0.9) * -1) *
                      Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) + 0)) /
              (Math.pow(1 + ((this.settings.interestRate / 100) / 12), (20 * 12)) - 1)
          ) - unit.guaranteedRental
      )
      return shortfall
    },
  },
  computed: {
    sortedUnits:function() {
      let agents = this.units
      return agents.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    totalUnits: function () {
      if (!isNil(this.units)) {
        return this.units.length;
      }

      return 0;
    },
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}

</style>
